export const BG_COVER = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/cover-new.jpg?updatedAt=1705391543801`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/dekstop.jpg?updatedAt=1704753830415`;

export const BG_COVER_SLIDESHOW_1 = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/s1.jpg?updatedAt=1704753827439`;
export const BG_COVER_SLIDESHOW_2 = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/s2.jpg?updatedAt=1704753827385`;
export const BG_COVER_SLIDESHOW_3 = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/s1.jpg?updatedAt=1704753827439`;
export const BG_COVER_SLIDESHOW_4 = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/s2.jpg?updatedAt=1704753827385`;
export const BG_COVER_SLIDESHOW_5 = `https://ik.imagekit.io/drpq5xrph/Template%20Tiffany%20&%20Jared/Slideshow/Slideshow-Cover-5.jpg?updatedAt=1698222444372`;

export const IMG_BRIDE_GROOM = `https://ik.imagekit.io/drpq5xrph/Template%20Tiffany%20&%20Jared/4.%20Bride%20_%20Groom?updatedAt=1698223108396`;
export const IMG_LOVE_STORY = `https://ik.imagekit.io/drpq5xrph/Template%20Tiffany%20&%20Jared/9.%20Love%20Story?updatedAt=1698223306540`;

export const BG_RSVP = `https://ik.imagekit.io/drpq5xrph/Template%20Tiffany%20&%20Jared/5.%20BG%20RSVP?updatedAt=1698223151527`;
export const BG_GIFTCARD = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/gift.jpg?updatedAt=1704753827335`;
export const BG_IG_FILTER = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/filter.jpg?updatedAt=1704753827360`;
export const BG_LIVESTREAM = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/live.jpg?updatedAt=1704753827358`;

export const IMG_FOOTER_1 = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/f1.jpg?updatedAt=1704753827399`;
export const IMG_FOOTER_2 = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/f2.jpg?updatedAt=1704753827333`;
export const IMG_FOOTER_3 = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/f3.jpg?updatedAt=1704753827443`;

export const IMG_LOGO = `https://user-images.githubusercontent.com/10141928/162121855-c5b68987-ca66-415e-b53a-42ac77189f29.png`;

import ImgMan from '@/assets/images/dokter-1.gif'
export const IMG_MAN = ImgMan;
import ImgGirl from '@/assets/images/dokter-2.gif'
export const IMG_GIRL = ImgGirl;
import ImgBoy from '@/assets/images/dokter-3.gif'
export const IMG_BOY = ImgBoy;
export const IMG_QR_COVER = `https://ik.imagekit.io/drpq5xrph/Template%20Tiffany%20&%20Jared/7.%20Wedding%20Filter.jpg?updatedAt=1695372281892`;

export const IMG_PHOTO_1 = 'https://ik.imagekit.io/masansgaming/Template_Hendry_Clarissa/1_i7-EQ33yp.jpg?updatedAt=1679724192432';
export const IMG_PHOTO_2 = 'https://ik.imagekit.io/masansgaming/Template_Hendry_Clarissa/2_NUamww_9b.jpg?updatedAt=1679724181595';
export const IMG_PHOTO_CONTENT = 'https://user-images.githubusercontent.com/10141928/162121692-3e7a781f-6b2c-4cc4-be9e-004df53eb69d.jpg';

export const IMG_AYAT = 'https://user-images.githubusercontent.com/10141928/160853754-d5b769cd-701f-4fe1-ba72-e493bee8a122.jpg';

export const IMG_SLIDE_1 = `https://ik.imagekit.io/invitatoid/template-rainajanssen/slide_1_n8StaSLkS.jpg?updatedAt=1638541151789`;
export const IMG_SLIDE_2 = `https://ik.imagekit.io/invitatoid/template-rainajanssen/slide_2_11_dNMMPw.jpg?updatedAt=1638541152275`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
export const SONG_CDN_URL = `https://res.cloudinary.com/invitato/video/upload/v1640358887/template-song/LeeHi_-_ONLY_Lyrics_nzuosa.mp4`;
